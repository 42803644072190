import React from 'react'
import './header.css'
import CTA from './CTA'

const Header = () => {
  return (
    <header>
      <section id='header'>
        <div className="container header__container">
          <h1>Welcome to Webvolutionize</h1>
          <p className="tagline">Where Vision Meets Virtual: Webvolutionize Your World</p>
          <CTA />

          <a href='#contact' className='scroll__down'>Scroll Down</a>
        </div>
      </section>
    </header>
  )
}

export default Header