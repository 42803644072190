import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What We Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className='service'>
          <div className="service__head">
            <h3>Web Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <span><BiCheck className='service__list-icon' /></span>Full-stack web development with HTML, JavaScript, and CSS.</li>
            <li>
              <span><BiCheck className='service__list-icon' /></span>Responsive UI/UX design.</li>
            <li>
            <span><BiCheck className='service__list-icon' /></span>Implementing designs that bring your concepts to life, ensuring a seamless transition from vision to reality.</li>
          </ul>
        </article>
        {/* END OF UI/UX Design */}
        
        <article className='service'>
          <div className="service__head">
            <h3>Graphic Design</h3>
          </div>

          <ul className='service__list'>
            <li>
              <span><BiCheck className='service__list-icon' /></span>Creating compelling branding designs.</li>
            <li>
              <span><BiCheck className='service__list-icon' /></span>Designing user interfaces for websites.</li>
            <li>
              <span><BiCheck className='service__list-icon' /></span>Print design services for brochures, business cards, and more..</li>
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}


        <article className='service'>
          <div className="service__head">
            <h3>Programming</h3>
          </div>

          <ul className='service__list'>
            <li>
              <span><BiCheck className='service__list-icon' /></span>Mobile app development for iOS and Android.</li>
            <li>
              <span><BiCheck className='service__list-icon' /></span>Desktop application development for various platforms.</li>
            <li>
              <span><BiCheck className='service__list-icon' /></span>Web application development tailored to your needs.</li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}

      </div>
    </section>
  )
}

export default Services