import React, { useRef } from 'react';
import './contact.css'
import { MdEmail } from 'react-icons/md';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_kw71dfi', 'template_uw0az0e', form.current, 'f5G2YhByUoZZgnZkF')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });

    e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Us</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdEmail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>info@webvolutionize.co.uk</h5>
              <a href='mailto:info@webvolutionize.co.uk' target='_blank' rel="noreferrer">
                Send an Email
              </a>
            </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' className='name__placeholder' placeholder='Your Name' required />
          <input type="email" name='email' className='email__placeholder' placeholder='Your Email' required />
          <input type="text" name='subject' className='subject__placeholder' placeholder='Subject' required />
          <textarea name="message" rows="7" className='message__placeholder' placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact