import React from 'react'
import './about.css'
import TEAM_IMAGE from '../../assets/team-about.jpg';
import {FaAward} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'

const about = () => {
  const teamExperience = 10;
  const completedProjects = 5;

  return (
    <section id='about'>
      <h5>Discover</h5>
      <h2>About Us</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
          <img src={TEAM_IMAGE} alt="About" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">

            <article className="about__card">
              <FaAward className="about-icon"/>
              <h5>Expert Team</h5>
              <small>{teamExperience}+ Years Collective Experience</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about-icon"/>
              <h5>Completed Projects</h5>
              <small>{completedProjects}+</small>
            </article>
          </div>

          <p>
            Welcome to Webvolutionize! We are a passionate team of web developers dedicated to crafting professional and innovative websites for our clients.
            With over {teamExperience} years of collective experience, we've successfully delivered {completedProjects}+ projects, ranging from small business websites to
            complex web applications.
          </p>

          <p>
            Our expertise includes front-end web development, ensuring that your website not only looks stunning but also provides a seamless and engaging user experience.
            Whether you need a new website, a redesign, or additional features, we're here to transform your vision into a reality.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Discuss Your Project</a>

        </div>
      </div>

    </section>
  )
}

export default about